.homepage-nav {
  text-align: center;
  border-top: 10vh rgba(255, 255, 255, 0) solid;
}

.nav a, .homepage-nav a {
  text-decoration: none;
  font-weight: 500;
  padding: 20px;
  color: var(--title-gradient-from);
  transition: all 0.5s;
}

.nav a:hover, .homepage-nav a:hover {
  color: var(--accent);
}

@media only screen and (min-device-width: 768px)  {  
  .mobileNav {
    display: none;
  }

  .nav {
    position: fixed;
    text-align: right;
    right: 10vw;
    top: 2vh;
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    border-top: 3vh rgba(255, 255, 255, 0) solid;
    border-bottom: 3vh rgba(255, 255, 255, 0) solid;
    padding: 0px 2vh;
    border-radius: 1000px;
    /* border-right: 10vw rgba(255, 255, 255, 0) solid; */
    z-index: 100;
  }

  .nav1 {
    position: fixed;
    top: 0;
    width: 100%;
    text-align: right;
    background-color: white;
    border-top: 5vh white solid;
    border-bottom: 5vh white solid;
    border-left: 10vw white solid;
    z-index: 99;
  }

  .is-active {
    font-weight: 800 !important;
  }
}


@media only screen and (max-device-width: 767px) {
  html, body {height:100%;overflow:auto}

  .nav {
    display: none;
  }

  .nav1 {
    position: fixed;
    top: 0;
    width: 100%;
    text-align: right;
    font-family: 'Raleway';
    background-color: white;
    border-top: 4vh white solid;
    border-bottom: 4vh white solid;
    border-left: 10vw white solid;
    z-index: 99;
  }

  .mobileNav {
    position: fixed;
    right: 4vw;
    top: 4vw;
    z-index: 100;
  }

  .mobileNav img {
    filter: var(--icons-neutral);
    transition: all 0.5s;
    opacity: 0.25;
  }

  .mobileNav img:hover {
    filter: invert(38%) sepia(74%) saturate(6446%) hue-rotate(329deg) brightness(86%) contrast(106%);
    opacity: 0.7;
  }

  img.hamburger {
    width: 8vw;
  }
}

.nav {
  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -o-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}
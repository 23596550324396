@media screen  {
    .main {
      padding-top: 20vh;
      padding-left: 10%;
      padding-right: 10%;
      /* width: 80%; */
      height: auto;
    }

    
    .imgBg {
      left: 0;
      z-index: -11;
      width: 100%;
      height: 100%;
      /* background-image: url("./../img/holborn.jpg"); */
      background-color: var(--accent);
      background-blend-mode: lighten;
      background-position-x: center;
      background-position-y: 60%;
      background-size: cover;
      -o-background-size: cover;
      -moz-background-size: cover;
      -webkit-background-size: cover;
      background-repeat: no-repeat;
      opacity: 0.7;
    }

    .imgBgShell {
      z-index: -10;
      left: 0;
      width: 97%;
      height: 100%;
      position: absolute;
      opacity: 1;
      overflow: hidden;
    }

    .cameraPopover {
      position: fixed;
      left: 0;
      bottom: 1vh;
    }

    .ant-drawer-content {
      background-color: var(--background) !important;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .imgBgShell {
    width: 92%;
  }

  .ant-card-meta-description {
    display: none;
  }

  .ant-card-bordered {
    height: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .imgBgShell {
    width: 94%;
  }

  .imgBg {
    background-size: 100% 100%;
  }
}

@media only screen and (max-device-width: 767px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .imgBgShell {
    width: 100%;
  }

  .title h1 {
    font-size: 20vw !important;
  }

  .ant-card-meta-description {
    display: none;
  }
}
.homepage-title {
    text-align: center;
}

.homepage-title h1 {
    font-size: 3vw;
}

.homepage-title img {
    height: 150px;
}

@media only screen and (max-device-width: 767px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .homepage-title h1 {
        font-size: 10vw;
    }
}
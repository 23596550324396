@media only screen and (max-device-width: 767px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
    .mobileMenu {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.9);
        z-index: 200;
    }

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      .mobileMenu {
          background: rgba(255, 255, 255, 0.5);
          -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
      }
    }

    .mobileMenuInner {
        width: 100%;
        height: 80%;
        margin-top: 20vh;
        text-align: center;
    }

    .mobileMenuInner a {
        color: var(--title-gradient-from);
        font-size: 9vw;
        /* font-weight: 100; */
    }

    .mobileMenuInner a:active, .mobileMenuInner a:hover {
        color: var(--accent) !important;
    }
  
    .title h1 {
      font-size: 20vw !important;
    }

    .mobileNavCross {
        position: fixed;
        right: 4vw;
        top: 4vw;
        z-index: 100;
      }
    
    .mobileNavCross img {
        filter: var(--icons-mobile-menu);
        transition: all 0.5s;
      }
    
    .mobileNavCross img:hover {
        filter: invert(38%) sepia(74%) saturate(6446%) hue-rotate(329deg) brightness(86%) contrast(106%);
        opacity: 0.7;
      }
  }
.bottomline {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1vh;
    background-color: #E01A4F;
    border-top: 1vh;
    /* border-style: solid none none none;
    border-color: #0C090D; */
    text-align: center;
    z-index: 100;
  }